import React from "react";

const serializers = {
  types: {
    block(props) {
      switch (props.node.style) {
        default:
          return <p>{props.children}</p>;
      }
    },
  },
  marks: {
    internalLink: ({ mark, children }) => {
      const ref = mark.reference;
      const slug = ref.slug.current;
      let href = "";
      if (ref._type == "project") {
        href = `/work/${slug}`;
      }
      if (ref._type == "asofEntry") {
        href = `/asof/${slug}`;
      }

      return <a href={href}>{children}</a>;
    },
    link: ({ mark, children }) => {
      // Read https://css-tricks.com/use-target_blank/
      const { blank, href } = mark;
      return blank ? (
        <a href={href} target="_blank" rel="noopener">
          {children}
        </a>
      ) : (
        <a href={href}>{children}</a>
      );
    },
  },
};

export default serializers;
